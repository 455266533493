/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { forwardRef } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
// AFP-2532 TODO: Fix automatic suppressions below
// eslint-disable-next-line @atlassian/tangerine/import/entry-points
import { borderRadius, colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { type NudgeTag, type NudgeTooltipPulseProps } from './types';
import { fg } from '@atlaskit/platform-feature-flags';

const baseShadow = '0 0 0 3px';
const basePulseColor = token('color.border.accent.purple', colors.P75);
const basePulseShadowColor = token('color.border.accent.purple', 'rgba(101, 84, 192, 1)');
const easing = 'cubic-bezier(0.55, 0.055, 0.675, 0.19)';

const pulseAnimation = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'@keyframes pulseKeyframes': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'0%': {
			opacity: 0,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'50%': {
			opacity: 1,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'100%': {
			opacity: 0,
		},
	},
	animation: `pulseKeyframes 3000ms ${easing} infinite`,
});

const pseudoStyle = css({
	content: "''",
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
});

export const nudgePulse = ({
	hasPulse,
	pulseBorderRadius,
	pulseColor,
	pulseShadowColor,
}: NudgeTooltipPulseProps) =>
	css({
		display: 'block',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> *': {
			position: 'relative',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			'&::before': [
				pseudoStyle,
				{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					borderRadius: `${pulseBorderRadius}px`,
					boxShadow: `${baseShadow} ${pulseColor}`,
					opacity: hasPulse ? 1 : 0,
					transition: 'opacity 0.1s ease-in-out',
					pointerEvents: 'none',
				},
			],
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			'&::after': [
				pseudoStyle,
				{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					borderRadius: `${pulseBorderRadius}px`,
					boxShadow: `${baseShadow} ${pulseColor}, 0 0 11px ${pulseShadowColor}`,
					opacity: 0,
					pointerEvents: 'none',
				},
				hasPulse && pulseAnimation,
			],
		},
	});

export const NudgeTooltipPulse = forwardRef<
	NudgeTag,
	React.AllHTMLAttributes<HTMLElement> & NudgeTooltipPulseProps
>(
	(
		{
			hasPulse,
			pulseBorderRadius = borderRadius(),
			pulseColor = basePulseColor,
			pulseShadowColor = basePulseShadowColor,
			tag: TargetContainer = 'span',
			...rest
		},
		ref,
	) => {
		// Doing a cast because typescript is struggling to narrow the type
		const CastTargetContainer = TargetContainer as React.ElementType;

		return (
			<CastTargetContainer
				css={[
					nudgePulse({
						hasPulse,
						pulseBorderRadius,
						pulseColor,
						pulseShadowColor,
					}),
					fg('jira_onboarding_experiences_a11y_fixes') && inHeritWidthStyles,
				]}
				ref={ref}
				{...rest}
			/>
		);
	},
);

const inHeritWidthStyles = css({ width: 'inherit' });
